#form {
    color: white;
    border-radius: 10px;
    padding: 1rem 2rem;
    width: 40%;
    margin: 0 auto;
}

#form .form-div1 {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

#form .form-div1 label {
    margin-top: .3rem;
    font-weight: 500;
}

#form .form-div1 span {
    color: red;
}

#form .form-div1 input,
#form .form-div1 select {
    border-radius: 5px;
    padding: .5rem 5px;
    background-color: #F7FAFD;
    border: 1px solid rgba(0, 0, 0, .2);
}

#form .form-div1 #servico option {
    text-transform: uppercase;
}

#form .form-div1 .form-div3 {
    margin-top: .5rem;
}

#form .form-div1 .form-div3 .title {
    font-size: 1rem;
    text-align: left;
    color: white;
    text-transform: capitalize;
}

#form .form-div1 .form-div3 .row {
    margin-top: .3rem;
    display: flex;
    gap: 4rem;
}

#form .form-div1 .form-div3 .row .item {
    display: flex;
    align-items: center;
    gap: .4rem;
}

#form .form-div1 .form-div3 .row .item input {
    margin-top: .5rem;
    width: fit-content;
}

#form .form-div2 .checkbox {
    margin-top: 1.5rem;
}

#form .form-div2 .checkbox label {
    margin-left: .5rem;
    font-weight: 500;
}

#form .form-div2 .checkbox button {
    background-color: transparent;
    cursor: pointer;
    margin-top: .5rem;
    width: 100%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #form {
        width: 70%;
    }

    #form .form-div1 input,
    #form .form-div1 select {
        width: 100%;
    }

    #form .form-div1 .form-div3 .row {
        justify-content: space-between;
    }

    #form .form-div1 .form-div3 .row .item {
        gap: .4rem;
    }

    #form .form-div1 .form-div3 .row .item input {
        margin-top: .5rem;
    }

}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #form {
        width: 100%;
        margin-left: 0;
    }
}