#header .header1 {
    background: url("../../assets/BANNER_TOPO.png") no-repeat center center;
    background-size: cover;
    height: 92vh;
}

#header .header1 .container {
    height: 100%;
    display: flex;
    align-items: flex-end;
}

#header .header1 .container #ctaForm {
    text-align: left;
    margin-left: 2.5rem;
}

#header .header2 .container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

#header .header2 .container .top {
    display: flex;
    gap: 2rem;
}

#header .header2 .container .top .left {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

#header .header2 .container .top .left figure {
    text-align: left;
}

#header .header2 .container .top .left figure img {
    width: 50%;
}

#header .header2 .container .top .right figure img { 
    width: 90%;
}

#header .header2 .container .top .right .mobile {
    display: none;
}

#header .header2 .container .top .left .left_text,
#header .header2 .container .top .right .mobile .left_text {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    font-weight: 500;
}

#header .header2 .container .top .left .left_text p,
#header .header2 .container .top .right .mobile .left_text p {
    font-size: 1.5rem;
}

#header .header2 .container .top .left .left_text .text,
#header .header2 .container .top .right .mobile .left_text .text {
    font-weight: 700;
}

#header .header2 .container .top .left .little,
#header .header2 .container .top .right .mobile .little {
    color: #767676;
    font-size: .9rem;
    font-weight: 500;
}

#header .header2 .container .top .left .price,
#header .header2 .container .top .right .mobile .price {
    color: #0154A8;
    font-size: .9rem;
    font-weight: 700;
    margin-top: -1rem;
}

#header .header2 .container .bottom #ctaWpp img {
    width: 60%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #header .header1 {
        background: url("../../assets/mobile/BANNER_TOPO.png") no-repeat center center;
        background-size: cover;
        height: 92vh;
    }

    #header .header1 .container #ctaForm {
        text-align: center;
        margin-left: 0;
    }

    #header .header2 .container .top {
        flex-direction: column;
    }

    #header .header2 .container .top .left .left_text,
    #header .header2 .container .top .left .little,
    #header .header2 .container .top .left .price {
        display: none;
    }
    
    #header .header2 .container .top .right .mobile {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    #header .header2 .container .top .right .mobile .price {
        margin-top: 0;
    }

    #header .header2 .container .bottom figure img {
        width: 60%;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #header .header1 {
        height: 80vh;
    }

    #header .header2 .container .bottom figure img {
        width: 80%;
    }

    #header .header2 .container .top .left .title { 
        text-align: left;
    }
}