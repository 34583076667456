#about {
  background-color: var(--color-bg-variant);
}

#about .about1 .container {
  display: flex;
  justify-content: space-between;
}

#about .about1 .container .left {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#about .about1 .container .left .text {
  font-weight: 500;
  width: 80%;
}

#about .about1 .container .left ul {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#about .about1 .container .left ul li {
  display: flex;
  gap: .2rem;
  color: #03288E;
  font-weight: 500;
}

#about .about1 .container .left ul li img {
  width: 2%;
}

#about .about1 .container .left ul li:last-child {
  margin-left: 1.5rem;
  font-weight: 900;
}

#about .about1 .container .left #ctaForm {
  text-align: left;
}

#about .about1 .container .left #ctaForm img { 
  width: 35%;
}

#about .about1 .container .right {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 5rem;
}

#about .about1 .container .right .text {
  color: #03288E;
  font-weight: 700;
  font-size: 1.2rem;
  width: 60%;
  text-align: right;
}

#about .about1 .container .right .text span {
  color: #FF9345;
}

#about .about1 .container .right figure {
  text-align: left;
}

#about .about1 .container .right figure img {
  width: 60%;
}

#about .about1 .container .right #ctaForm {
  display: none;
}

#about .about2 {
  background-color: #0F1831;
}

#about .about2 .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#about .about2 .container figure img {
  width: 12%;
}

#about .about2 .container .title {
  color: white;
  width: 77%;
  margin: 0 auto;
}

#about .about2 .container .title span {
  color: #10C6FF;
}

#about .about2 .container .gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 3rem;
  margin: 2rem 0;
}

#about .about2 .container .gallery li {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: white;
}

#about .about2 .container .gallery li p {
  font-size: 1.2rem;
}

#about .about2 .container .gallery li img {
  width: 18%;
  max-height: 60%;
}

#about .about2 .container #ctaWpp img {
  width: 60%;
}

#about .about3 {
  background-color: white;
}

#about .about3 .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#about .about3 .container .title {
  text-align: center;
}

#about .about3 .container .info {
  display: grid;
  grid-template-columns: 30% 1fr 30%;
  gap: 1rem;
}

#about .about3 .container .info .left,
#about .about3 .container .info .right {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#about .about3 .container .info .left figure,
#about .about3 .container .info .right figure {
  text-align: left;
}

#about .about3 .container .info .left figure img,
#about .about3 .container .info .right figure img {
  width: 30%;
}

#about .about3 .container .info .left p,
#about .about3 .container .info .right p {
  width: 100%;
}

#about .about3 .container .info .left {
  margin-left: 0rem;
}

#about .about3 .container .info .middle {
  text-align: center;
  width: 100%;
}

#about .about3 .container .info .middle img {
  width: 100%;
}

#about .about4 .container {
  display: flex;
  justify-content: space-between;
}

#about .about4 .container .left {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#about .about4 .container .left .text {
  font-weight: 500;
  width: 80%;
  color: #03288E;
}

#about .about4 .container .left .alvo {
  display: none;
}

#about .about4 .container .left .text2 {
  font-weight: 700;
}

#about .about4 .container .left ul {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#about .about4 .container .left ul li {
  display: flex;
  align-items: center;
  gap: .5rem;
  color: #03288E;
  font-weight: 500;
  width: 80%;
}

#about .about4 .container .left ul li img {
  max-width: 4%;
}

#about .about4 .container .left #ctaForm {
  text-align: left;
}

#about .about4 .container .left #ctaForm img { 
  width: 35%;
}

#about .about4 .container .right {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 5rem;
}

#about .about4 .container .right {
  text-align: left;
}

#about .about4 .container .right img {
  width: 70%;
}


/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
  #about .about1 .container {
    flex-direction: column;
  }

  #about .about1 .container .left .text {
    width: 100%;
  }

  #about .about1 .container .left ul li {
    gap: .4rem;
    align-items: center;
  }

  #about .about1 .container .left ul li img {
    min-width: 3%;
    max-width: 4%;
  }

  #about .about1 .container .left ul li:last-child {
    margin-left: 1.5rem;
    font-weight: 900;
  }

  #about .about1 .container .left #ctaForm {
    display: none;
  }

  #about .about1 .container .right {
    align-items: flex-end;
  }

  #about .about1 .container .right .text {
    font-size: 1.4rem;
  }

  #about .about1 .container .right figure {
    text-align: center;
  }

  #about .about1 .container .right figure img {
    width: 40%;
  }

  #about .about1 .container .right #ctaForm {
    display: block;
  }

  #about .about2 .container .gallery li img {
    width: 30%;
    max-height: 60%;
  }

  #about .about2 .container #ctaWpp img {
    width: 60%;
  }
  
  #about .about3 .container .info {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  #about .about3 .container .info .left,
  #about .about3 .container .info .right {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  #about .about3 .container .info .left figure img,
  #about .about3 .container .info .right figure img {
    width: 20%;
  }
  
  #about .about3 .container .info .left p,
  #about .about3 .container .info .right p {
    font-size: 1.2rem;
  }
  
  #about .about3 .container .info .middle {
    order: -1;
  }
  
  #about .about3 .container .info .middle img {
    width: 80%;
  }

  #about .about4 .container {
    flex-direction: column;
  }

  #about .about4 .container .left {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  #about .about4 .container .left .text {
    text-align: center;
    width: 100%;
  }

  #about .about4 .container .left .alvo {
    display: block;
  }

  #about .about4 .container .left .alvo img {
    width: 30%;
  }

  #about .about4 .container .left .text2 {
    font-weight: 700;
    text-align: center;
  }

  #about .about4 .container .left ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  #about .about4 .container .left ul li {
    width: 70%;
  }

  #about .about4 .container .left ul li img {
    width: 6%;
  }

  #about .about4 .container .left ul li:last-of-type img {
    width: 6%;
  }

  #about .about4 .container .left #ctaForm {
    text-align: center;
  }

  #about .about4 .container .right {
    display: none;
  }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
  #about .about1 .container .left .title {
    text-align: left;
  }

  #about .about1 .container .left ul li img {
    min-width: 4%;
    max-width: 4%;
  }

  #about .about1 .container .right figure img {
    width: 70%;
  }

  #about .about2 .container .gallery {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(6, 1fr);
    gap: 1rem;
  }

  #about .about2 .container .gallery li img {
    width: 20%;
    max-height: 60%;
  }

  #about .about2 .container #ctaWpp img {
    width: 80%;
  }

  #about .about4 .container .left .alvo img {
    width: 40%;
  }

  #about .about4 .container .left ul li {
    width: 100%;
  }

  #about .about4 .container .left #ctaForm img {
    width: 70%;
  }
}