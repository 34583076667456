#footer .footer1 .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
}

#footer .footer1 .container .left {
    text-align: left;
}

#footer .footer1 .container .left img {
    width: 30%;
}

#footer .footer1 .container .right {
    display: flex;
    flex-direction: column;
}

#footer .footer1 .container .right .right_title {
    font-size: 1.2rem;
    color: #020CFF;
    text-align: center;
}

#footer .footer2 .container {
    display: flex;
    align-items: center;
    padding: 1rem 0;
}

#footer .footer2 .container .text {
    font-weight: 500;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #footer .footer1 .container {
        flex-direction: column;
    }

    #footer .footer1 .container .left {
        text-align: center;
        border-top: 1px solid #D9D9D9;
        padding-top: 1rem;
    }

    #footer .footer1 .container .right {
        order: -1;
    }

    #footer .footer2 .container {
        justify-content: center;
        text-align: center;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #footer .footer1 .container .left img {
        width: 40%;
    }

    #footer .footer1 .container .right img {
        width: 80%;
    }
}