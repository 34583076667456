#success {
    background: url("../../assets/success.png") no-repeat center center;
    background-size: cover;
    height: 100vh;
}

#success .container {
    height: 100%;
    display: flex;
    align-items: center;
    padding-top: 7rem;
    margin-left: 3rem;
    width: 45%;
}

#success .container .success-title {
    color: white;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #success {
        background: url("../../assets/mobile/success.png") no-repeat center center;
        background-size: cover;
        height: 80vh;
    }

    #success .container {
        align-items: flex-start;
        padding-top: 20vh;
        width: 80%;
        text-align: center;
        font-size: 1.5rem;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #success .container {
        padding-top: 9rem;
        font-size: 1.2rem;
    }
}