#menu .container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin-top: -6.7vh;  
}

#menu .container figure img {
    width: 25%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #menu .container {
        margin-top: -2.5vh;  
    }

    #menu .container figure img {
        width: 50%;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #menu .container figure img {
        width: 60%;
    }
}