#content .content1 .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#content .content1 .container .title {
  text-align: center;
}

#content .content1 .container #ctaWpp img {
  width: 60%;
}

#content .content2 {
  background-color: #F8F8FF;
}

#content .content2 .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#content .content2 .container .title {
  text-align: center;
}

#content .content2 .container .gallery {
  display: flex;
  justify-content: center;
}

#content .content2 .container .gallery .item {
  width: 100%;
}

#content .content2 .container .gallery .item figure {
  width: 100%;
}

#content .content2 .container .gallery .item figure img {
  width: 90%;
}

#content .content2 .container .gallery .item #ctaForm {
  margin-top: -6.5rem;
  margin-left: 8rem;
}

#content .content2 .container .gallery .item #ctaForm img { 
  width: 40%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
  #content .content1 .container figure img {
    width: 40%;
  }

  #content .content1 .container #ctaWpp img {
    width: 60%;
  }

  #content .content2 .container .gallery {
    flex-direction: column;
    gap: 2rem;
  }

  #content .content2 .container .gallery .item #ctaForm {
    margin-top: -11rem;
    margin-left: -1rem;
    position: absolute;
  }

  #content .content2 .container .gallery .item #ctaForm img { 
    width: 50%;
  }
  
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
  #content .content1 .container figure img {
    width: 60%;
  }

  #content .content1 .container #ctaWpp img {
    width: 100%;
  }

  #content .content2 .container .gallery .item #ctaForm {
    margin-top: -6rem;
    margin-left: -.5rem;
    position: absolute;
  }

  #content .content2 .container .gallery .item #ctaForm img { 
    width: 60%;
  }
}