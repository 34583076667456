#FormPage {
  background-color: #322FA7;
}

#FormPage .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#FormPage .container .title {
  text-align: center;
  color: #FCBB45;
}

#FormPage .container .subtitle {
  text-align: center;
  color: white;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    
}